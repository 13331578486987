// import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SlideShow from "../components/slider"
import Blurb from "../components/blurb"
import Header from "../components/header"
import Seo from "../components/seo"
import { Row, Container } from "react-bootstrap"
import BodyCard from "../components/bodyCards"

export const query = graphql`
  {
    tireLogo: file(relativePath: { eq: "tire-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blackWheel: file(relativePath: { eq: "black-wheel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brownWheel: file(relativePath: { eq: "brown-wheel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    silverWheel: file(relativePath: { eq: "silver-wheel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tireStack: file(relativePath: { eq: "tire-stack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wetTire: file(relativePath: { eq: "wet-tire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fastCar: file(relativePath: { eq: "fast-car.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    protection: file(relativePath: { eq: "road-protection.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flatTire: file(relativePath: { eq: "flat-tire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TiresRims = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const flatTire = data.flatTire.childImageSharp.fluid
  const tireLogo = data.tireLogo.childImageSharp.fluid
  const silverWheel = data.silverWheel.childImageSharp.fluid
  const brownWheel = data.brownWheel.childImageSharp.fluid

  const content = [
    {
      title: "Repair Damaged Rim",
      description: "We will repair or replace your damaged rim",
      image: `${flatTire.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "No Deductible",
      description: "Installation cost is also included.",
      image: `${silverWheel.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Unlimited Kilometers",
      description: "Terms up to 6 years.",
      image: `${brownWheel.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
  ]

  const text =
    "Secure Ride Tire and Rim protects you against cost and worry of issues that exist everyday regarding your tires and rims. We understand that wheels and tires keep us on the road and that every car needs them whether that vehicle is brand new or even used. "
  const cardContent = {
    1: {
      img: data.tireStack.childImageSharp.fluid,
      alt: "Stacked Tires",
      list: [
        "Available for new or used vehicles",
        "Up to 6 year unlimited kilometers coverage",
        "Cost associated with tire change or towing assistance",
        "Protection against natural and man made hazards",
      ],
      header: "Benefits of Tire and Rim Protection:",
    },
    2: {
      img: data.wetTire.childImageSharp.fluid,
      alt: "Wet Driving Conditions",
      list: [
        "Debris from other vehicles",
        "Fallen rocks",
        "Nails and screws",
        "Glass",
      ],
      header: "We protect against damage caused by:",
    },
    3: {
      img: data.protection.childImageSharp.fluid,
      alt: "Road Hazard Protection",
      list: [
        "Repairing damaged tire",
        "Repairing damaged rim",
        "Replace damaged tire or rim if repairs cannot be done",
        "Covers original or replacement tires",
      ],
      header: "Secure Ride Tire & Rim includes:",
    },
    4: {
      img: data.blackWheel.childImageSharp.fluid,
      alt: "Black Wheel",
      list: [
        "All related taxes and levies",
        "Tire mounting",
        "Tire balancing",
        "Full installation",
      ],
      header: "Tire and Rim Coverage includes paying:",
    },
  }

  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "tire-rim" }}
    >
      <Seo
        title="Autoguard"
        keywords={[`tires`, `damage`, `protection`, `towing`, `replacement`]}
      />
      <SlideShow data={content} />
      <Header logo={tireLogo.src} />
      <Blurb text={text} />
      <Container fluid>
        <Row>
          <BodyCard content={cardContent} />
        </Row>
      </Container>
    </Layout>
  )
}

export default TiresRims
